body {
  // css variable
  //use property: var(--main-bg-color)
  --color-Alizarin-Crimson: #e92f2c;
  --color-Cornflower-Blue: #4593f5;
  --color-Niagara: #05ac9e;

  --color-Silver-Chalice: #a1a1a1;
  --color-Dusty-Gray: #959595;
  --color-Gallery: #f0f0f0;
  --color-Gallery-opacity-2: rgba(240, 240, 240, 0.2);
  --color-Scorpion: #606060;
  --color-Alto: #d8d8d8;
  --color-Link-Water: #eff3fb;
  --color-Alabaster: #f9f9f9;
  --color-Seagull: #81CAE9;
  --color-Mine-Shaft: #343434;

  --default-text-color: var(--color-Mine-Shaft);

  --table-head-background-color: var(--color-Gallery);
  --gnb-menu-active-color: var(--color-Cornflower-Blue);
  --login-focus-color: var(--color-Cornflower-Blue);
  --login-error-color: var(--color-Alizarin-Crimson);
}