* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0px;
    word-spacing: -0.5px;
}

section,
article,
aside,
footer,
header,
nav,
hgroup {
    display: block;
}

em,
cite,
address,
dfn,
var {
    font-style: normal;
}

div,
p,
ol,
ul,
li,
img,
a,
h1,
h2,
h3,
h4,
h5,
h6,
form,
span,
iframe,
dl,
dt,
dd,
blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

ul,
ol {
    list-style: none;
}

select,
file {
    border: 1px solid #ffffff;
    font-size: 16px;
    color: #767676;
    vertical-align: middle;
}

textarea,
input {
    vertical-align: middle;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
}

fieldset {
    margin: 0px;
    padding: 0px;
    border: none;
}

button {
    border: none;
    cursor: pointer;
}

/* ios */
input[type="text"],
input[type="password"],
input[type="submit"],
input[type="search"],
input[type="button"],
textarea {
    -webkit-appearance: none;
}

a[href^="tel"] {
    text-decoration: inherit;
    color: inherit;
}

/* Table
---------------------------------------------------*/
table,
th,
td {
    margin: 0;
    padding: 0;
    font-size: 16px;
    border-collapse: collapse;
    border-spacing: 0;
}

table caption {
    font-size: 22px;
    text-align: left;
    /*display: none;*/
}

/* For Screen Reader
---------------------------------------------------*/
legend {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}

/* link
---------------------------------------------------*/
a,
a:link,
a:hover,
a:active,
a:focus,
a:visited {
    color: #000000;
    text-decoration: none;
}

/* image boder 0
---------------------------------------------------*/
img {
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

/* Style none
---------------------------------------------------*/
/*hr,*/
/*caption {*/
/*display: none;*/
/*}*/
col {
    background: none;
}

