// Breakpoints
$breakpoint-mobile: 335px;
$breakpoint-tablet: 758px;
$breakpoint-desktop: 1024px;

// Mobile common left and right padding
body {
  --left-right-padding: 10px;
}

$header-height: 48px;