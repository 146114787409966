i[class^="icon_"],
button[class^="icon_"] {
  display: block;
  width: 24px;
  min-height: 24px;
  background-position: center;
}

.btn_more_left {
  width: 28px !important;
  height: 28px !important;
  background: url("assets/image/common/btn-more-left.svg") no-repeat;
}

.btn_more_right {
  width: 28px !important;
  height: 28px !important;
  background: url("assets/image/common/btn-more-right.svg") no-repeat;
}

.btn_pause {
  width: 28px !important;
  height: 28px !important;
  background: url("assets/image/common/btn-pause.svg") no-repeat;
}

.btn_start {
  width: 28px !important;
  height: 28px !important;
  background: url("assets/image/common/btn-start.svg") no-repeat;
}

.icon_menu {
  background: url("assets/image/common/ic-menu.svg") no-repeat;
}

.icon_search {
  background: url("assets/image/common/ic-search.svg") no-repeat;
}

.icon_user_profile {
  background: url("assets/image/common/ic-user-profile.svg") no-repeat;
}

.icon_facebook_share {
  width: 46px !important;
  height: 44px !important;
  background: url("assets/image/common/ic-share-facebook.svg") no-repeat;
}

.icon_kakao_share {
  width: 46px !important;
  height: 44px !important;
  background: url("assets/image/common/ic-share-kakaotalk.svg") no-repeat;
}

.icon_search {
  background: url("assets/image/common/ic-search.svg") no-repeat;
}

.icon_add {
  background: url("assets/image/common/ic-add.svg") no-repeat;
}

.icon_exit {
  background: url("assets/image/common/ic-exit.svg") no-repeat;
}

.icon_upload_user {
  width: 17px !important;
  height: 16px !important;
  background: url("assets/image/common/ic-user.svg") no-repeat;
}

.icon_upload_time {
  width: 17px !important;
  min-height: 16px !important;
  height: 16px !important;
  background: url("assets/image/common/ic-time.svg") no-repeat;
}

.icon_pause {
  width: 28px !important;
  height: 28px !important;
  background: url("assets/image/common/btn-pause.svg") no-repeat;
}

.icon_arrow_top_more {
  width: 20px !important;
  height: 16px !important;
  background: url("assets/image/common/ic-arrow-top-more.svg") no-repeat;
}

.icon_arrow_bottom_more {
  width: 20px !important;
  height: 16px !important;
  background: url("assets/image/common/ic-arrow-right-more.svg") no-repeat;
}

.icon_comment {
  width: 16px !important;
  height: 16px !important;
  background: url("assets/image/common/ic-comment.svg") no-repeat;
}

.icon_download {
  background: url("assets/image/common/ic-download.svg") no-repeat;
}

.icon_posting {
  background: url("assets/image/common/ic-posting.svg") no-repeat;
}

.icon_del {
  background: url("assets/image/common/ic-del.svg") no-repeat;
}